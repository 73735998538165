import styled from 'styled-components';

export const GlobalStyles = styled.div<{
  fontFamily: string | undefined;
  // fontScale: number | undefined;
  fontUrl: string | undefined;
  // grayMode?: boolean;
}>`
  @font-face {
    font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto'}, sans-serif`};
    font-weight: 400;
    src: local(
        "${({ fontFamily }) => `${fontFamily || 'Roboto'}, sans-serif`};"
      ),
      url("${({ fontUrl }) => `${fontUrl || '/src/assets/fonts/Roboto-Regular.ttf'}, sans-serif`}")
        format("truetype");
  }
  body {
    margin: 0;
    font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto'}, sans-serif`};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

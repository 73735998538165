import React, { useState, useEffect, useMemo } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { SocialNetworksModuleStyles } from './SocialNetworksModuleStyles';
import { SocialNetworksModuleProps } from './types';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { translations } from 'i18n/translations';
import { getErrorMessage, handleKeyUp } from 'utils/handleValidateError';
import { setPhone } from 'state/slices/optionSlice';
import { debounce } from 'lodash';
import {
  MuteIcon,
  WhatsAppIcon,
  ViberIcon,
  CallIcon,
  TelegramIcon,
  MessageIcon,
  EmailIcon,
} from 'assets/icons';
import { FormErrorMessage } from 'components/formErrorMessage';
import { RadioButton } from '../radioButton/RadioButton';
import { SimpleField } from '../simpleField/SimpleField';
import {
  useFormSettings,
  useSettings,
} from 'hooks/formSettings/useFormSettings';
import { useLanguage } from 'hooks/language/useLanguage';
import { TConnectionChannel } from 'entities/IExtraField';

export function SocialNetworksModule({
  name,
  index,
  values,
  error,
  setFieldValue,
  required,
  handleClick,
  selectedNetwork,
  question,
  extraBlockStyles,
  description,
  botsUrls,
  changeRequired,
  hideError = false,
  // companyID,
  setChosenNetwork,
  defaultPhoneCode,
  answerPositionV2,
}: SocialNetworksModuleProps) {
  const dispatch = useAppDispatch();
  const { touched, errors } = useFormikContext();
  const language = useLanguage().language?.name ?? 'UA';
  const { companyID } = useSettings();
  const { isPageByPageForm } = useFormSettings();
  const { countryCode } = useAppSelector((state) => state.form.formSettings);

  const [errorMessage, setErrorMessage] = useState<any>({});

  const isCountryCodeNotEditable = values?.countryCodeNotEditable;

  const hidePhoneInput = useMemo(
    () => values.answer?.filter(
      (ans: { key: string; value: any; phoneInputRequired?: boolean }) => ans.key === selectedNetwork,
    )[0]?.phoneInputRequired,
    [selectedNetwork],
  );

  // @ts-ignore
  const errorNameColor = isPageByPageForm // @ts-ignore
    && errors?.extraFields?.[1]?.answer?.some((item) => item?.name) // @ts-ignore
    && touched?.extraFields?.[1]?.answer
    ? '#f5cecb'
    : '#F9F9F9';
  // @ts-ignore
  const errorPlaceholderColor = isPageByPageForm
    // @ts-ignore
    && errors?.extraFields?.[1]?.answer?.some((item) => item?.name) // @ts-ignore
    && touched?.extraFields?.[1]?.answer
    ? '#000000'
    : '';

  function getSocialNetworkIcon(
    type: 'email' | 'sms' | 'call' | 'telegram' | 'whatsapp' | 'without answer',
  ) {
    const types = {
      email: (
        <div className="socialItem">
          <EmailIcon color={changeRequired ? '#ccc' : '#636363'} />
        </div>
      ),
      sms: (
        <div className="socialItem">
          <MessageIcon color={changeRequired ? '#ccc' : '#636363'} />
        </div>
      ),
      call: (
        <div className="socialItem">
          <CallIcon color={changeRequired ? '#ccc' : '#0E9285'} />
        </div>
      ),
      telegram: (
        <div className="socialItem">
          <TelegramIcon color={changeRequired ? '#ccc' : '#32A9E1'} />
        </div>
      ),
      whatsapp: (
        <div className="socialItem">
          <WhatsAppIcon color={changeRequired ? '#ccc' : 'rgb(43,207,56)'} />
        </div>
      ),
      viber: (
        <div className="socialItem">
          <ViberIcon color={changeRequired ? '#ccc' : '#7B519C'} />
        </div>
      ),
      'without answer': (
        <div className="socialItem">
          <MuteIcon color={changeRequired ? '#ccc' : '#8d8d8d'} />
        </div>
      ),
    };

    return types[type];
  }

  const networksNames = {
    'without answer': translations[language].without_answer,
    email: translations[language].email,
    viber: translations[language].viber,
    telegram: translations[language].telegram,
    whatsapp: translations[language].whatsapp,
    call: translations[language].call,
    sms: translations[language].sms,
  };

  const botsDescriptionTexts = {
    telegram: translations[language].telegram_bot_usage_text_1,
    viber: translations[language].viber_bot_usage_text_1,
  };

  const botsDescriptionTexts2 = {
    telegram: translations[language].telegram_bot_usage_text_2,
    viber: translations[language].viber_bot_usage_text_2,
  };

  const optionsListStyle = isPageByPageForm
    ? 'options changeFlexDirection'
    : 'options';

  const isAnswerArray = Array.isArray(values?.answer);

  const networkIndex = isAnswerArray
    ? values?.answer?.findIndex(
      (item: { key: string; value: string }) => item.key === selectedNetwork,
    )
    : 0;
  const isNameInputShouldBeShown = values?.nameInputForNetworks?.includes(selectedNetwork);
  const showInputs = selectedNetwork && selectedNetwork !== 'without answer';
  const isTelegramOrViber = selectedNetwork === 'telegram' || selectedNetwork === 'viber';

  useEffect(() => {
    if (
      isCountryCodeNotEditable
      && networkIndex !== -1
      && selectedNetwork !== 'without answer'
      && selectedNetwork !== 'email'
      && countryCode === 'ua'
    ) {
      setFieldValue(
        `extraFields[${index}].answer[${networkIndex}].value`,
        '+380',
      );
      setFieldValue(`extraFields[${index}].answer[${networkIndex}].name`, '');
    }
    if (
      isCountryCodeNotEditable
      && networkIndex !== -1
      && selectedNetwork !== 'without answer'
      && selectedNetwork === 'email'
      && countryCode === 'ua'
    ) {
      setFieldValue(`extraFields[${index}].answer[${networkIndex}].value`, '');
      setFieldValue(`extraFields[${index}].answer[${networkIndex}].name`, '');
    }
  }, [networkIndex, isCountryCodeNotEditable, selectedNetwork]);

  useEffect(() => {
    if (!isPageByPageForm && selectedNetwork) {
      setChosenNetwork(selectedNetwork);
    }
  }, [isPageByPageForm, selectedNetwork]);

  const phoneInputDebounce = debounce((value) => {
    if (value && values.smsValidationPhone) {
      dispatch(setPhone(value));
    }
  }, 800);

  return (
    <SocialNetworksModuleStyles
      disabled={!!changeRequired}
      style={extraBlockStyles}
    >
      {question && (
        <div
          id="question"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={questionTextByFormType(
            isPageByPageForm,
            answerPositionV2!,
            question,
          )}
          className="titleContainer"
        >
          <h3>
            {questionTextByFormType(
              isPageByPageForm,
              answerPositionV2!,
              question,
            )}
          </h3>
        </div>
      )}
      {description && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={description}
          className="titleContainer"
        >
          <h3>{description}</h3>
        </div>
      )}
      <div className="optionsContainer">
        <div className={optionsListStyle}>
          {values.options.map(
            (field: { value: any; id: string }, subIndex: number) => (!isPageByPageForm ? (
              <div className="checkboxFieldContainer" key={field.id}>
                <button
                  aria-label={
                      networksNames[field.value as TConnectionChannel]
                    }
                  disabled={changeRequired}
                  type="button"
                  id={field.value}
                  onClick={() => {
                    handleClick(subIndex);
                    setFieldValue(
                      `extraFields[${index}].selectedOption`,
                      field.value,
                    );
                    setChosenNetwork(field.value);
                    if (networkIndex !== -1) {
                      setFieldValue(
                        `extraFields[${index}].answer[${networkIndex}].value`,
                        '',
                      );
                      setFieldValue(
                        `extraFields[${index}].answer[${networkIndex}].name`,
                        '',
                      );
                    }
                  }}
                  className={
                      (values.answer?.[field.value] !== undefined
                        ? 'selected'
                        : '')
                      + (field.value === selectedNetwork ? ' active' : '')
                    }
                >
                  <div className="iconContainer">
                    {getSocialNetworkIcon(field.value)}
                  </div>
                </button>
                <div className="radioText">
                  {networksNames[field.value as TConnectionChannel]}
                </div>
              </div>
            ) : (
              <RadioButton
                id={1}
                index={index}
                key={field.id}
                name={`options[${subIndex}]`}
                value={field.value}
                onChange={
                    setFieldValue
                      ? (event) => {
                        setFieldValue(
                          `extraFields[${index}].selectedOption`,
                          event.target.value,
                        );
                        handleClick(subIndex);
                        setChosenNetwork(event.target.value);
                        if (networkIndex !== -1) {
                          setFieldValue(
                            `extraFields[${index}].answer[${networkIndex}].value`,
                            '',
                          );
                          setFieldValue(
                            `extraFields[${index}].answer[${networkIndex}].name`,
                            '',
                          );
                        }
                      }
                      : () => null
                  }
                checked={
                    values?.selectedOption === values.options[subIndex].value
                  }
              >
                <div className="radioButtonInnerContainer">
                  <div className="iconContainer">
                    {getSocialNetworkIcon(field.value)}
                  </div>
                  <div className="radioText">
                    {networksNames[field.value as TConnectionChannel]}
                  </div>
                </div>
              </RadioButton>
            )),
          )}
        </div>
        {/* {showInputs && (
          <div className="networkInputField">
            {isNameInputShouldBeShown
              && (values?.type === 'connection_channel'
                || values?.type === 'networks')
              && isAnswerArray && (
                <SimpleField
                  defaultPhoneCode={defaultPhoneCode}
                  // isPageByPageForm={isPageByPageForm}
                  index={index}
                  name={`extraFields[${index}].answer[${networkIndex}].name`}
                  errorName={`extraFields[${index}].answer`}
                  onChange={setFieldValue}
                  onKeyUp={() => handleKeyUp(
                    `extraFields[${index}].answer[${networkIndex}.name]`,
                    setErrorMessage,
                    error,
                  )}
                  value={values?.answer[networkIndex]?.name || ''}
                  error={
                    typeof errorMessage === 'object'
                      ? getErrorMessage(
                        `extraFields[${index}].answer`,
                        errorMessage,
                      )
                      : undefined
                  }
                  placeholder={`${translations[language].enter_your_name}...`}
                  inputType="text"
                  required
                  hideError
                  inputStylesSpecial={errorNameColor}
                  inputPlaceholderSpecial={errorPlaceholderColor}
                />
            )}
            {(values?.type === 'connection_channel'
              || values?.type === 'networks')
              && hidePhoneInput
              && selectedNetwork !== undefined
              && selectedNetwork !== 'email'
              && isAnswerArray && (
                <SimpleField
                  defaultPhoneCode={defaultPhoneCode}
                  // isPageByPageForm={isPageByPageForm}
                  index={index}
                  name={`extraFields[${index}].answer[${networkIndex}].value`}
                  errorName={`extraFields[${index}].answer`}
                  onChange={setFieldValue}
                  onKeyUp={() => handleKeyUp(
                    `extraFields[${index}].answer[${networkIndex}.value]`,
                    setErrorMessage,
                    error,
                  )}
                  debounceChange={phoneInputDebounce}
                  value={values?.answer[networkIndex]?.value || ''}
                  error={
                    typeof errorMessage === 'object'
                      ? getErrorMessage(
                        `extraFields[${index}].answer`,
                        errorMessage,
                      )
                      : undefined
                  }
                  placeholder={`${translations[language].enter_telephone_number}...`}
                  inputType="phoneNumber"
                  required={false}
                  countryCodeNotEditable={isCountryCodeNotEditable}
                  hideError
                />
            )}
            {(values?.type === 'connection_channel'
              || values?.type === 'networks')
              && selectedNetwork !== undefined
              && selectedNetwork === 'email'
              && isAnswerArray && (
                <SimpleField
                  defaultPhoneCode={defaultPhoneCode}
                  // isPageByPageForm={isPageByPageForm}
                  index={index}
                  name={`extraFields[${index}].answer[${networkIndex}].value`}
                  errorName={`extraFields[${index}].answer`}
                  onChange={setFieldValue}
                  onKeyUp={() => handleKeyUp(
                    `extraFields[${index}].answer[${networkIndex}.value]`,
                    setErrorMessage,
                    error,
                  )}
                  value={values?.answer[networkIndex]?.value || ''}
                  error={
                    typeof errorMessage === 'object'
                      ? getErrorMessage(
                        `extraFields[${index}].answer`,
                        errorMessage,
                      )
                      : undefined
                  }
                  placeholder={`${translations[language].enter_email}`}
                  inputType="text"
                  required={false}
                  hideError
                />
            )}
          </div>
        )} */}
        {(isPageByPageForm || companyID === 166)
          && isTelegramOrViber
          && botsUrls && (
            <div className="botsDescriptionContainer">
              <p className="botName">
                {selectedNetwork.charAt(0).toUpperCase()
                  + selectedNetwork.slice(1)}
              </p>
              <p className="botDescriptionText">
                {companyID === 166
                  ? botsDescriptionTexts2[selectedNetwork]
                  : botsDescriptionTexts[selectedNetwork]}
              </p>
              <p className="botDescriptionText">
                {companyID === 166 ? (
                  translations[language].bot_usage_text2
                ) : (
                  <>
                    {translations[language].bot_usage_text}{' '}
                    <b>{translations[language].start_bot_text}</b>
                  </>
                )}
              </p>
            </div>
        )}
        {selectedNetwork === 'sms' && companyID === 166 && (
          <div className="botsDescriptionContainer">
            <p className="botName">SMS</p>
            <p className="botDescriptionText">
              Для спілкування в онлайн чаті використовуйте Telegram або Viber
            </p>
          </div>
        )}

        <div className="formErrorContainer">
          {error && <span aria-live="polite">{error}</span>}
          {!error && touched && (
            <ErrorMessage name={name} component={FormErrorMessage} />
          )}
        </div>
      </div>
    </SocialNetworksModuleStyles>
  );
}

import styled from 'styled-components';

export const FormStyles = styled.div<{
  fontFamily: string | undefined;
  isFontWeightBold: boolean;
  companyId: number;
  isTransparentBg: boolean;
  fontScale?: number | undefined;
  fontUrl?: string | undefined;
  grayMode?: boolean;
  isOpened?: boolean;
}>`
  font-size-adjust: ${({ fontScale }) => fontScale};
  filter: ${({ grayMode }) => (grayMode ? 'grayscale(100%)' : 'inherit')} !important;
  font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto-Medium'}, sans-serif`};
  .floatingBtn {
  }
  .floatingMenu {
    transition: all 0.3s;
    z-index: 100;
    background-color: white;
    right: 20px;
    bottom: 50px;
    position: absolute;
    border: 1px solid black;
    width: ${({ isOpened }) => (isOpened ? 180 : 50)}px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    justify-content: ${({ isOpened }) => (isOpened ? 'space-evenly' : 'center')};
    align-items: center;
  }

  .formContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0;
    box-sizing: border-box;
    min-height: 100vh;

    .success {
      width: 100%;
      max-width: 544px;
      min-height: calc(100vh - 200px);
      display: flex;
      justify-content: center;
      align-items: center;

      .body {
        padding: 48px 16px;
      }

      h3 {
        font-weight: 900;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 24px;
      }

      p {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
      }
    }

    form {
      width: 100%;
      max-width: 544px;
    }

    @media (max-width: 420px) {
      padding: 0;
    }
  }

  .body {
    padding-bottom: 38px;
    width: 100%;
    background: ${({ isTransparentBg }) => `${
    isTransparentBg
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.6) 20%,#fff 50%,#fff)'
      : '#fff'
  }`};
    backdrop-filter: blur(40px);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 420px) {
      height: 100%;
      border-radius: 0px;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .header {
      height: 52px;
      padding: 0 32px;
      padding-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;

      .dividingLine {
        margin: 0 auto;
        bottom: -15px;
        border-radius: 6px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
      }

      .headerInnerContainer {
        display: flex;
        width: 60%;
        justify-content: space-between;

        @media (min-width: 360px) {
          width: 56%;
        }

        .languageSelect {
          padding-top: 14px;
          position: relative;
          color: #000000;
        }

        .selectedLanguage {
          color: #000;
          font-size: 20px;
          font-weight: 600;
          display: flex;
          gap: 5px;
          align-items: center;

          img {
            max-width: 25px;
          }

          &.opened {
            svg {
              transform: rotate(-180deg);
              transition: all 0.3s;
            }
          }

          svg {
            margin-left: 8px;
            margin-bottom: 2px;
            transition: all 0.3s;
          }
        }

        .logoImage {
          .logo {
            max-height: 35px;
            object-fit: contain;
          }
        }

        .languages {
          width: auto;
          min-width: 70px;
          position: absolute;
          top: 35px;
          left: -4px;
          background-color: #fff;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          padding: 4px 0;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

          button {
            height: 28px;
            padding: 0 12px;
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #000;

            img {
              max-width: 20px;
            }
          }
        }
      }
    }

    .mainContentContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      .extraFieldListContainer {
        display: flex;
        flex-direction: column;
        // background-color: #fff;
        border-radius: 4px;
        /* padding: 32px; */
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
        box-sizing: border-box;

        @media (max-width: 420px) {
          justify-content: center;
          align-items: center;
        }

        .formExtraFields {
          width: 100%;

          .extraFieldContainer {
            /* background-color: #fff;
          border-radius: 4px;
          padding: 16px; */

            &:not(:last-child) {
              margin-bottom: 18px;
            }
          }

          .titleContainer {
            position: relative;

            span {
              position: absolute;
              top: 0;
              left: -8px;
              color: #ffffff;
              font-size: 10px;
            }
          }

          h3 {
            //  font-weight: ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
            font-weight: "700";
            font-size: 20px;
            line-height: 24px;
            color: #202020;
            margin-top: 0;
            margin-bottom: 14px;
          }

          .documentsContainer {
            margin-bottom: 16px;

            .documentDescription {
              margin-bottom: 30px;
            }
          }
        }

        // STYLES FOR BACK AND NEXT BUTTON //
        .buttonsContainer {
          width: 100%;

          &.buttonsContainerFlex {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            @media (min-width: 370px) {
              flex-direction: row;
            }
          }
        }

        .documentContainer {
          margin-bottom: 16px;
        }
      }

      button[type="submit"] {
        margin-top: 18px;
      }
    }
    .uploadErrorContainer {
      margin-top: 16px;

      .uploadError {
        color: #ff3333;
      }
    }
  }

  .thankYouPageStyles {
    @media (max-width: 420px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    .mascotWrapper {
      display: flex;
      justify-content: center;
      margin: 20px 0;

      .mascotContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 21px;

        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .thankYouPageLogoContainer {
      max-width: 150px;
      margin: 0 auto;

      .thankYouPageLogo {
        display: block;
        margin: 0 auto;
        width: 100%;
      }
    }

    .completeButtonRaw {
      display: flex;
      justify-content: center;
      margin-top: 22px;

      .completeButtonContainer {
        width: 240px;
      }
    }
  }
`;

export const StepsTextStyles = styled.div<{
  bottomLineColor: string | undefined;
  isFontWeightBold: boolean;
  textColor: string | undefined;
  fontFamily: string | undefined;
  companyId: number;
}>`
  font-family: ${({ fontFamily }) => `${fontFamily || 'Roboto-Medium'}, sans-serif`};
  .stepsTextContainer {
    margin-top: 16px;
    width: 100%;
    font-weight: ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};

    .stepsText {
      font-weight: ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '400'}`};
      color: ${({ textColor }) => `${textColor || '#000000'}`};
      &::after {
        content: "";
        margin-left: auto;
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 10px;
        background-color: ${({ bottomLineColor }) => `${bottomLineColor}` || '#0E9285'};
      }
    }
  }
`;

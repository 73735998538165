import {
  CheckboxField,
  Document,
  FileUploadPermissionModule,
  HiddenField,
  QuantityField,
  RadioField,
  ScaleField,
  SimpleField,
} from 'components/form';
import { LinkButtonField } from 'components/form/link_button';
import { CaptionField } from 'components/form/caption';
import { DatePickerField } from 'components/form/datePicker';
import { DropdownField } from 'components/form/dropdown';
import { ParagraphField } from 'components/form/paragraph';
import { PictureField } from 'components/form/picture';
import { IExtraField } from 'entities/IExtraField';

export function createPicture(field: IExtraField, moscotUrl?: string) {
  const isMoscot = field.options?.find(
    (opt) => opt.key === 'is_moscot' && opt.value === 'true',
  );
  const url = !!isMoscot && moscotUrl ? moscotUrl : field.question ?? '';
  return <PictureField url={url} />;
}

export function createCaption(field: IExtraField) {
  const description = field.options?.find(
    (e) => e.key === 'description',
  )?.value;

  return (
    <CaptionField
      index={+(field?.position ?? 0)}
      description={description!}
      text={field.question ?? ''}
    />
  );
}

export function createParagraph(field: IExtraField) {
  return (
    <ParagraphField
      index={+(field?.position ?? 0)}
      text={field.question ?? ''}
    />
  );
}

export function createButton(field: IExtraField) {
  return (
    <LinkButtonField
      text={field.question ?? ''}
      link={field.options?.find((e) => e.key === 'link')?.value}
      isBlank={!!field.options?.find((e) => e.key === 'blank')?.value}
    />
  );
}

export function createSimpleField(
  field: IExtraField,
  inputType: 'text' | 'textarea' | 'phoneNumber',
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleKeyUp: any,
  errorMessage: any,
  getErrorMessage: any,
  values: any,
) {
  const isCountryCodeEditable = field?.type === 'phone' ? field?.countryCodeNotEditable : true;

  return (
    <SimpleField
      id={field.id}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      // isPageByPageForm={isPageByPageForm}
      description={field?.questionDescription}
      index={selectedFieldIndex}
      name={`extraFields[${field.id}|||s].answer`}
      errorName={`extraFields[${field.id}|||s].answer`}
      onChange={setFieldValue}
      onKeyUp={() => handleKeyUp(`extraFields[${field.id}|||s].answer`)}
      value={selectedField?.answer || ''}
      error={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].answer`)
          : undefined
      }
      question={selectedField?.question}
      placeholder={selectedField?.placeholder ?? ''}
      inputType={inputType}
      required={field?.required || false}
      countryCodeNotEditable={isCountryCodeEditable}
      defaultPhoneCode={field?.defaultPhoneCode}
      showAdditionalField={field?.isAdditionalFieldShown || false}
      placeholderForAdditionalField={field?.placeholderForAdditionalField}
      additionalFieldValue={
        values?.extraFields[selectedFieldIndex]?.additionalFieldAnswer || ''
      }
      hideError
    />
  );
}

export function createCheckboxField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleChange: any,
  errorMessage: any,
  getErrorMessage: any,
  values: any,
) {
  return (
    <CheckboxField
      id={field.id}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      description={field?.questionDescription}
      index={selectedFieldIndex}
      values={values}
      name={`extraFields[${field.id}|||s].question`}
      optionsName={`extraFields[${field.id}|||s].answers`}
      optionsError={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].answers`)
          : undefined
      }
      handleChangeOptionsState={handleChange!}
      question={selectedField?.question ?? ''}
      required={field?.required || false}
      handleChangeChoiceDescription={setFieldValue}
    />
  );
}

export function createRadioField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  setNextFieldId: any,
  errorMessage: any,
  getErrorMessage: any,
  values: any,
) {
  return (
    <RadioField
      id={field.id}
      // isPageByPageForm={isPageByPageForm}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      description={field?.questionDescription}
      index={selectedFieldIndex}
      values={values}
      optionsName={`extraFields[${field.id}|||s].selectedOption`}
      optionsError={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].selectedOption`)
          : undefined
      }
      handleChangeOptionsState={setFieldValue}
      question={selectedField?.question ?? ''}
      required={field?.required || false}
      handleChangeChoiceDescription={setFieldValue}
      handleSetNextFieldId={setNextFieldId}
    />
  );
}

export function createHiddenField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
) {
  return (
    <HiddenField
      index={selectedFieldIndex}
      name={`extraFields[${field.id}|||s].answer`}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      value={selectedField?.answer || ''}
      onChange={setFieldValue}
    />
  );
}

export function createScaleField(
  field: IExtraField,
  selectedFieldIndex: number,
  handleRatingChange: (value: number | number[], field: IExtraField) => void,
) {
  const scaleValues = field?.answers
    ?.map((item) => Number(item.text))
    ?.sort((a, b) => a - b);
  const maxValue = Number(scaleValues![scaleValues!.length - 1]);
  // @ts-ignore
  const scaleFieldValue = field?.type === 'scale_color'
    ? [Number(field?.answer)]
    : Number(field?.answer);

  return (
    <ScaleField
      id={field.id}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      field={field}
      // isPageByPageForm={isPageByPageForm}
      description={field?.questionDescription}
      signatureMin={field?.signatureMin}
      signatureMax={field?.signatureMax}
      maxValues={maxValue}
      defaultValues={field?.answer || field?.scaleDefaultValue!}
      value={scaleFieldValue}
      errorName={`extraFields[${field.id}|||s].answer`}
      handleChange={(value) => handleRatingChange(value, field)}
      required={field?.required || false}
      index={selectedFieldIndex}
      question={field?.question}
    />
  );
}

export function createFileUploadPermissionModule(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  errorMessage: any,
  getErrorMessage: any,
  values: any,
) {
  return (
    <FileUploadPermissionModule
      id={field.id}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      // isPageByPageForm={isPageByPageForm}
      description={field?.questionDescription}
      name={`extraFields[${field.id}|||s].answer`}
      index={selectedFieldIndex}
      values={field}
      error={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].answer`)
          : undefined
      }
      setFieldValue={setFieldValue}
      required={field?.required || false}
      question={selectedField?.question}
    />
  );
}

export function renderSubmitButton() {}

export function createQuantityField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleKeyUp: any,
  errorMessage: any,
  getErrorMessage: any,
) {
  return (
    <QuantityField
      id={field.id}
      // @ts-ignore
      answerPositionV2={field?.answerPosition}
      // isPageByPageForm={isPageByPageForm}
      description={field?.questionDescription}
      index={selectedFieldIndex}
      name={`extraFields[${field.id}|||s].answer`}
      minValue={field?.quantityMinValue}
      maxValue={field?.quantityMaxValue}
      defaultValue={field?.quantityDefaultValue ?? field?.quantityMinValue}
      onKeyUp={() => handleKeyUp(`extraFields[${field.id}|||s].answer`)}
      onChange={setFieldValue}
      value={selectedField?.answer || ''}
      error={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].answer`)
          : undefined
      }
      question={selectedField?.question}
      required={field?.required || false}
      buttonsBackgroundColor={field?.quantityButtonsBackgroundColor}
      signsColor={field?.quantityButtonSignsColor}
      hideError
    />
  );
}

export const renderDropdown = (
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleKeyUp: any,
  errorMessage: any,
  getErrorMessage: any,
) => (
  <DropdownField
    id={field.id}
    setFieldValue={setFieldValue}
    // @ts-ignore
    value={selectedField.selectedOption}
    // @ts-ignore
    answerPositionV2={selectedField?.answerPosition}
    question={field.question ?? ''}
    name={`extraFields[${field.id}|||s].selectedOption`}
    error={
      typeof errorMessage === 'object'
        ? getErrorMessage(`extraFields[${field.id}|||s].selectedOption`)
        : undefined
    }
    hideError
    options={
      selectedField.answers?.map((e: any) => ({
        value: e.id?.toString() ?? '',
        label: e.text ?? '',
      }))!
    }
  />
);

export function renderDateField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleKeyUp: any,
  errorMessage: any,
  getErrorMessage: any,
) {
  return (
    <DatePickerField
      id={field.id}
      setFieldValue={setFieldValue}
      value={selectedField.answer}
      // @ts-ignore
      answerPositionV2={selectedField?.answerPosition}
      question={field.question ?? ''}
      name={`extraFields[${field.id}|||s].answer`}
      error={
        typeof errorMessage === 'object'
          ? getErrorMessage(`extraFields[${field.id}|||s].answer`)
          : undefined
      }
      hideError
    />
  );
}

export function createConfirmationField(
  field: IExtraField,
  selectedFieldIndex: number,
  selectedField: any,
  setFieldValue: any,
  handleChange: any,
) {
  const setFiledVal = (key: any, val: any) => {
    setFieldValue(key, val);
  };

  const handuleChange = (e: any) => {
    console.log(e);
    // setFieldValue(key, val);
  };
  return (
    <Document // eslint-disable-next-line react/no-array-index-key
      key={selectedFieldIndex}
      field={selectedField}
      index={selectedFieldIndex}
      // @ts-ignore
      setTermsAgreed={field?.setTermsAgreed}
      setFieldValue={setFiledVal}
      handleChange={handuleChange}
    />
  );
}

import React, { useEffect, useRef, useState } from 'react';
import autosize from 'autosize';
import { ErrorMessage, useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SimpleFieldStyles } from './SimpleFieldStyles';
import { FormErrorMessage } from 'components/formErrorMessage';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { useAppSelector } from 'state/hooks';
import { SimpleFieldProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { IFormStateV2 } from 'pages/form/types';

export function SimpleField({
  id,
  // @ts-ignore
  answerPositionV2,
  index,
  name,
  errorName,
  error,
  value,
  onChange,
  onKeyUp,
  extraBlockStyles,
  disabled,
  title,
  question,
  placeholder,
  inputType,
  required,
  description,

  countryCodeNotEditable,
  inputStylesSpecial,
  inputPlaceholderSpecial,
  showAdditionalField,
  placeholderForAdditionalField,
  additionalFieldValue,
  debounceChange,
  hideError = false,
  defaultPhoneCode,
}: SimpleFieldProps) {
  const { touched, errors } = useFormikContext<IFormStateV2>();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const { inputPlaceholderColor } = useAppSelector(
    (state) => state.form.formStyles,
  );
  const { isPageByPageForm } = useFormSettings();
  const { countryCode } = useAppSelector((state) => state.form.formSettings);
  const [errorMessage, setErrorMessage] = useState<any>({});

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const inputPhoneStyles = isPageByPageForm
    // @ts-ignore
    && errors?.extraFields?.[1]?.answer?.some((item) => item?.value) // @ts-ignore
    && touched?.extraFields?.[1]?.answer
    ? { backgroundColor: '#f5cecb' }
    : {};

  // @ts-ignore
  const isTextareaAnswerErrored = isPageByPageForm // @ts-ignore
    && errors?.extraFields?.[0]?.answer // @ts-ignore
    && touched?.extraFields?.[0]?.answer;
  // @ts-ignore
  const isTextareaAdditionalFieldErrored = isPageByPageForm // @ts-ignore
    && errors?.extraFields?.[0]?.additionalFieldAnswer // @ts-ignore
    && touched?.extraFields?.[0]?.additionalFieldAnswer;

  const mainTextareaStyles = isTextareaAnswerErrored
    ? { backgroundColor: '#f5cecb' }
    : {};
  const additionalTextareaStyles = isTextareaAdditionalFieldErrored
    ? { backgroundColor: '#f5cecb' }
    : {};

  function handleKeyUp(key: string) {
    setErrorMessage(
      typeof errorMessage === 'object'
        ? { ...errorMessage, [key]: undefined }
        : errorMessage,
    );
  }

  useEffect(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [textareaRef.current]);
  const inputFieldStyles = isPageByPageForm
    ? { border: '1px solid rgba(29, 22, 26, 0.21)' }
    : { border: 'none' };

  function getField(type: 'text' | 'textarea' | 'phoneNumber') {
    switch (type) {
      case 'text':
        return (
          <input
            aria-label={placeholder}
            style={inputFieldStyles}
            name={name}
            type="text"
            value={value}
            onChange={(event) => {
              onChange(name, event.target.value);
            }}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            disabled={disabled}
            className={disabled ? 'disabled' : ''}
          />
        );
      case 'textarea':
        return (
          <>
            <textarea
              aria-label={placeholder}
              maxLength={2048}
              style={{ ...inputFieldStyles, ...mainTextareaStyles }}
              name={name}
              ref={textareaRef}
              value={value}
              onChange={(event) => onChange(name, event.target.value)}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={disabled ? 'disabled' : ''}
            />
            {showAdditionalField && (
              <textarea
                aria-label={placeholder}
                maxLength={2048}
                style={{ ...inputFieldStyles, ...additionalTextareaStyles }}
                name={`extraFields[${index}].additionalFieldAnswer`}
                ref={textareaRef}
                value={additionalFieldValue}
                onChange={(event) => onChange(
                  `extraFields[${index}].additionalFieldAnswer`,
                  event.target.value,
                )}
                onKeyUp={() => handleKeyUp(`extraFields[${index}].additionalFieldAnswer`)}
                placeholder={placeholderForAdditionalField}
                disabled={disabled}
                className={disabled ? 'disabled' : ''}
              />
            )}
          </>
        );
      case 'phoneNumber':
        return (
          <PhoneInput
            inputStyle={inputPhoneStyles}
            countryCodeEditable={!countryCodeNotEditable}
            disableDropdown={window.location.hostname.includes('ukrposhta')}
            country={(defaultPhoneCode ?? countryCode).toLowerCase()}
            value={value}
            onChange={(value) => {
              onChange(name, value);
              if (debounceChange) {
                debounceChange(value);
              }
            }}
            excludeCountries={['ru']}
            placeholder={placeholder}
            disabled={disabled}
            enableSearch={!window.location.hostname.includes('ukrposhta')}
          />
        );
    }
  }

  return (
    <SimpleFieldStyles
      style={extraBlockStyles}
      fontFamily={fontFamily}
      placeHolderColor={inputPlaceholderSpecial || inputPlaceholderColor}
      inputBackground={inputStylesSpecial}
    >
      {question && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive
          tabIndex={0}
          id="question"
          aria-label={question}
          className="titleContainer"
        >
          <h3>
            {questionTextByFormType(
              isPageByPageForm,
              answerPositionV2 ?? 0,
              question,
            )}
          </h3>
        </div>
      )}

      {description && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive
          tabIndex={0}
          id="question"
          aria-label={description}
          className="titleContainer"
        >
          <h3>{description}</h3>
        </div>
      )}

      <div className="inputContainer">
        <div className="inputRaw">{getField(inputType)}</div>
        {hideError && (
          <div className="formErrorContainer">
            {error && <span aria-live="polite">{error}</span>}
            {touched && (
              <ErrorMessage name={name} component={FormErrorMessage} />
            )}
          </div>
        )}
      </div>
    </SimpleFieldStyles>
  );
}

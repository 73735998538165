import React, { useCallback, useRef } from 'react';
import { SquareCheckboxStyles } from './SquareCheckboxStyles';
import { CheckMark } from 'assets/icons/CheckMark';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { useAppSelector } from 'state/hooks';
import { CheckboxProps } from './types';

export const SquareCheckbox: React.FC<CheckboxProps> = ({
  index,
  name,
  value,
  onChange,
  extraBlockStyles,
  checkboxWrapperStyles,
  customizedCheckboxContainerStyles,
  label,
  disabled,
  error,
  showError,
  children,
}) => {
  const { touched } = useFormikContext();

  const { elementsColor, notCheckedElementsColor } = useAppSelector(
    (state) => state.form,
  );

  const inputRef = useRef(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <SquareCheckboxStyles
      style={extraBlockStyles}
      elementsColor={elementsColor}
      defaultCheckboxBorderColor={notCheckedElementsColor}
    >
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input
          aria-label={label}
          className="checkboxDefault"
          type="checkbox"
          id={name}
          checked={value}
          ref={inputRef}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="checkboxWrapper" style={checkboxWrapperStyles}>
          <div style={customizedCheckboxContainerStyles}>
            <div
              className={
                value
                  ? 'customizedCheckbox customizedCheckboxChecked'
                  : 'customizedCheckbox'
              }
              style={label ? { marginTop: 16 } : {}}
              onChange={disabled ? () => null : handleOnClick}
            >
              {value && <CheckMark color={elementsColor || '#0E9285'} />}
            </div>
          </div>
          <div className="checkboxText">{children}</div>
        </div>
        {showError && (
          <div className="formErrorContainer">
            {error && <span aria-live="polite">{error}</span>}
            {!error && touched && (
              <ErrorMessage name={name} component={FormErrorMessage} />
            )}
          </div>
        )}
      </label>
    </SquareCheckboxStyles>
  );
};

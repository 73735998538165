import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { ColorScaleStyles } from './ColorScaleStyles';
import { isSafari } from 'react-device-detect';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { ColorScaleProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export function ColorScale({
  colors,
  pointerColor,
  maxValue,
  defaultValue,
  signatures,
  errorName,
  value,
  handleChange,
  scaleValues,
  signatureColor,
}: ColorScaleProps) {
  const { touched } = useFormikContext();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const minNumber = scaleValues?.includes(0) ? 0 : 1;

  const [assessment, setAssessment] = useState<number[]>([]);

  useEffect(() => {
    if (defaultValue) {
      handleChange([defaultValue]);
    } else {
      handleChange([0]);
    }
  }, [defaultValue]);

  return (
    <ColorScaleStyles
      signatureColor={signatureColor}
      isFontWeightBold={isFontWeightBold}
    >
      <div
        className="numbers"
        style={
          !isSafari
            ? {
              backgroundImage: `linear-gradient(90deg, ${colors?.join(
                ', ',
              )})`,
              backgroundClip: 'text',
              backgroundColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }
            : { color: '#000' }
        }
      >
        {scaleValues?.map((number) => (
          // eslint-disable-next-line react/no-array-index-key, implicit-arrow-linebreak
          <span key={number}>{number}</span>
        ))}
      </div>

      <Range
        label="Label"
        min={minNumber || 0}
        max={maxValue}
        values={value || assessment}
        onChange={
          handleChange
            ? (values) => handleChange(values)
            : (values) => setAssessment(values)
        }
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '14px',
              width: 'calc(100% - 20px)',
              borderRadius: 7,
              background: `linear-gradient(90deg, ${colors?.join(', ')})`,
              boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            aria-label="Вибір"
            style={{
              ...props.style,
              height: 35,
              width: 35,
              boxSizing: 'border-box',
              border: `5px solid ${pointerColor}`,
              borderRadius: 18,
              backgroundColor: pointerColor,
              boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.15)',
            }}
          />
        )}
      />

      <div className="signatures">
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={(minNumber || 0) + signatures[0]}
          className="signaturesText"
        >
          {signatures[0]}
        </div>
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          aria-label={maxValue + signatures[1]}
          className="signaturesText signaturesSecondText"
        >
          {signatures[1]}
        </div>
      </div>

      <div className="formErrorContainer">
        {touched && (
          <ErrorMessage name={errorName} component={FormErrorMessage} />
        )}
      </div>
    </ColorScaleStyles>
  );
}
